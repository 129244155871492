<template>
  <div id="app-pc">
    <!-- <div class="title">
      绩效考评
    </div> -->
    <div class="header">
      <div class="logo">
        <img
          :src="require('@/assets/logo.png')"
          class="logo-img"
        >
      </div>
      <div class="content">
        <div class="menus">
          <a
            v-for="menu in menus"
            :key="menu.label"
            :class="{active: isActive(menu.name)}"
            class="menu-item"
            href="javascript:;"
            @click="toPage(menu.name)"
          >
            {{menu.label}}
          </a>
        </div>
        <div class="user">
          <img class="headPortrait" src="@/assets/headPortrait.png" alt="">
          <div class="right">
            <p class="username">{{userInfo.nickname}}</p>
            <p class="username">{{userInfo.department}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="views">
      <router-view v-if="isShow" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'pc',
  provide(){
    return{
      reload:this.reload
    }
  },
  data() {
    return {
      menus: [
        {
          label: '问卷调查',
          name: 'questionnaire'
        },
        // {
        //   label: '联系人',
        //   name: 'contacts'
        // },
        // {
        //   label: '模板库',
        //   name: 'temp'
        // },
      ],
      isShow:true
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  methods: {
    reload(){
      this.isShow=false;
      this.$nextTick(()=>{
        this.isShow=true
      })
    },
    toPage(name) {
      const n = typeof name === 'string' ? name : name[0];
      this.$router.replace({ name: n });
    },
    isActive(name) {
      const rName = this.$route.name;
      if (typeof name === 'string') return name === rName;
      return name.includes(rName);
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
#app-pc {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  .right{
    padding-top: 5px;
  }
  .username{
    font-size: 9px !important;
    line-height: 15px !important;
  }

  .title {
    height: 67px;
    line-height: 67px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    background: #ffffff;
    color: #3d3d3d;
  }

  .header {
    display: flex;
    align-content: center;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px #f8f9fa;

    .logo {
      padding-top: 10px;
      width: 190px;

      .logo-img {
        display: block;
        margin: auto;
        width: 125px;
      }
    }

    .content {
      display: flex;
      align-content: center;
      justify-content: space-between;
      flex: 1;
      .headPortrait{
        width: 30px;
        margin-right: 5px;
      }
    }

    .menus {
      padding-top: 15px;
      .menu-item {
        margin-left: 40px;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        color: #3d3d3d;
        text-decoration: none;

        &.active {
          color: #008755;
        }
      }
    }

    .user {
      display: flex;
      align-items: center;
      margin-right: 16px;

      .icon {
        padding: 4px;
        margin-right: 8px;
        font-size: 24px;
        background: #000;
        color: #fff;
        border-radius: 50%;
      }

      p {
        font-size: 12px;
      }
    }
  }
}
</style>
